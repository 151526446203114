<ngb-carousel class="col-12" [interval]="0" #picCarousel="ngbCarousel" (slid)="onSlid($event)" (slide)="onSlide($event)">
  <ng-template ngbSlide id="img1">
    <img
      #pic1
      [src]="this.scUrlService.getCardUrl(this.enzaId)"
      [alt]="'General image of ' + this.cardName"
      class="img-fluid"
      width="1136"
      height="640"
      />
  </ng-template>
  <ng-template ngbSlide *ngIf="this.displayMov1" id="mov1">
    <video
      #mov1
      class="img-fluid"
      width=1136
      height=640
      muted
      [src]="this.scUrlService.getCardMovieUrl(this.enzaId)"
      type="video/mp4"
      onloadedmetadata="this.muted=true"
      (ended)="this.onMovieEnded()"></video>
  </ng-template>
  <ng-template ngbSlide id="img2">
    <img
      #pic2
      [src]="this.scUrlService.getFesCardUrl(this.enzaId)"
      [alt]="'Fes image of ' + this.cardName"
      class="img-fluid"
      width="1136"
      height="640"
      />
  </ng-template>
  <ng-template ngbSlide *ngIf="this.displayMov2" id="mov2">
    <video
      #mov2
      class="img-fluid"
      width=1136
      height=640
      muted
      [src]="this.scUrlService.getCardCostumeMovieUrl(this.enzaId)"
      type="video/mp4"
      onloadedmetadata="this.muted=true"
      (ended)="onMovieEnded()"></video>
  </ng-template>
</ngb-carousel>
