<div class="col-lg-6 col-md-12">
  <table class="table table-hover">
    <thead>
      <tr>
        <th i18n>Produce</th>
        <th i18n>Release</th>
        <th i18n>Duration (Day)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let card of this.timetable.produce">
        <td>
          <a
            [routerLink]="'/pcardinfo'"
            [queryParams]="{uuid: card.cardUuid}">
            {{ card.cardName }}
          </a>
        </td>
        <td>{{ card.releaseDate }}</td>
        <td>{{ this.timeDuration(card.releaseDate) }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="col-lg-6 col-md-12">
  <table class="table table-hover">
    <thead>
      <tr>
        <th i18n>Support</th>
        <th i18n>Release</th>
        <th i18n>Duration (Day)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let card of this.timetable.support">
        <td>
          <a
            [routerLink]="'/scardinfo'"
            [queryParams]="{uuid: card.cardUuid}">
            {{ card.cardName }}
          </a>
        </td>
        <td>{{ card.releaseDate }}</td>
        <td>{{ this.timeDuration(card.releaseDate) }}</td>
      </tr>
    </tbody>
  </table>
</div>
