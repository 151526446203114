
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Result</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 p-2" *ngIf="!this.queryResult.length">
        <p class="text-center m-0">No result!</p>
      </div>
      <div class="card col-md-4 col-sm-6 p-0" *ngFor="let i of this.queryResult">
        <a routerLink="/scardinfo"
          [queryParams]="{ uuid: i.E_CardUuid }"
          class="row g-0">
          <div class="col-3">
            <img [src]="this.scUrlService.getSCardIcon(i.EnzaID)" alt class="img-fluid">
          </div>
          <div class="col-9">
            <div class="card-body">
              <p class="card-title">
                {{i.E_CardName.replace(this.regExp, "$1")}}
              </p>
              <div class="card-text">
                Lv: {{this.getMinimumLevel(i)}}
              </div>
            </div>

          </div>
        </a>
      </div>
      <!--a *ngFor="let i of this.queryResult"
        routerLink="/scardinfo"
        [queryParams]="{ uuid: i.cardUuid }"
        class="img-fluid col-lg-2 col-md-4 col-sm-6">
        <img [src]="
          'https://viewer.shinycolors.moe/images/content/support_idols/icon/' + i.enzaId + '.png'
        " alt="">
        <p>{{i.cardName.replace(this.regExp, "$1")}}</p>
      </a-->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
