<div class="row m-0 p-0" *ngIf="this.idolInfo">
  <div class="d-flex col-lg-4 col-md-12 h-100 container justify-content-center" id="divIdolData">
    <div class="row">
      <div class="col-12 pt-2 d-block">
        <h1 class=" w-100 d-none d-md-block text-center">{{ this.idolInfo.idolName }}</h1>
        <img
          [src]="this.scUrlService.getIdolIcon(this.idolInfo.idolId)"
          [alt]="'Icon of ' + this.idolInfo.idolName"
          class="img-fluid mx-auto d-block"
          width="400"
          height="400"
          />
      </div>
      <div class="col-12 d-block">
        <table class="table w-100">
          <tbody>
            <tr class="row">
              <th class="col-2"></th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.hiragana }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Name</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.idolName }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Age</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.age }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Height</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.height }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Weight</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.weight }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Birthday</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.birthday }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Blood Type</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.bloodType }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Unit</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.unit.unitName }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>B/H/W</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.threeSize }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Hobby</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.interest }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>Specialty</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.specialSkill }}</td>
            </tr>
            <tr class="row">
              <th class="col-2  d-flex align-items-center" i18n>CV</th>
              <td class="col-10 d-flex align-items-center">{{ this.idolInfo.preCv ? this.idolInfo.preCv + " → " : '' }}{{ this.idolInfo.cv }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex col-lg-8 col-md-12 min-vh-100 container justify-content-center pt-3 pb-5" id="divInfo">
    <div class="row w-100">
      <div class="card col-12 w-100 overflow-auto ps-0 pe-0 h-100">
        <div class="card-header w-100">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <a href="javascript: void()" class="nav-link" (click)="togglePS= true" [ngClass]="{'disabled': togglePS, 'active': togglePS}" style="font-family: Meiryo" i18n>P卡列表</a>
            </li>
            <li class="nav-item">
              <a href="javascript: void()" class="nav-link" (click)="togglePS= false" [ngClass]="{'disabled': !togglePS, 'active': !togglePS}" style="font-family: Meiryo" i18n>S卡列表</a>
            </li>
          </ul>
        </div>
        <div class="card-body w-100 container overflow-auto h-100 justify-content-center">
          <div class="row w-100 m-0" [ngClass]="{'d-none': !togglePS}">
            <ng-container *ngIf="this.pSSR.length || this.pSR.length || this.pR.length">
              <h5 class="text-start pt-2" *ngIf="this.pSSR.length">P_SSR</h5>
              <app-card-item *ngFor="let card of this.pSSR" [cardInfo]="card" [pathType]="'/pcardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
              <h5 class="text-start pt-2" *ngIf="this.pSR.length">P_SR</h5>
              <app-card-item *ngFor="let card of this.pSR" [cardInfo]="card" [pathType]="'/pcardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
              <h5 class="text-start pt-2" *ngIf="this.pR.length">P_R</h5>
              <app-card-item *ngFor="let card of this.pR" [cardInfo]="card" [pathType]="'/pcardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            </ng-container>
            <ng-container *ngIf="!this.pSSR.length && !this.pSR.length && !this.pR.length">
              <div class="d-flex align-items-center justify-content-center w-100">
                <div class="d-flex ">
                  <div class="display-4" i18n>No Data</div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row w-100 m-0" [ngClass]="{'d-none': togglePS}">
            <ng-container *ngIf="this.sSSR.length || this.sSR.length || this.sR.length || this.sN.length">
              <h5 class="text-start pt-2" *ngIf="this.sSSR.length">S_SSR</h5>
              <app-card-item *ngFor="let card of this.sSSR" [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
              <h5 class="text-start pt-2" *ngIf="this.sSR.length">S_SR</h5>
              <app-card-item *ngFor="let card of this.sSR" [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
              <h5 class="text-start pt-2" *ngIf="this.sR.length">S_R</h5>
              <app-card-item *ngFor="let card of this.sR" [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
              <h5 class="text-start pt-2" *ngIf="this.sN.length">S_N</h5>
              <app-card-item *ngFor="let card of this.sN" [cardInfo]="card" [pathType]="'/scardinfo'" class="col-md-6 col-lg-4 col-sm-12 pt-1 pb-1"></app-card-item>
            </ng-container>
            <ng-container *ngIf="!this.sSSR.length && !this.sSR.length && !this.sR.length && !this.sN.length">
              <div class="d-flex align-items-center justify-content-center w-100">
                <div class="d-flex ">
                  <div class="display-4" i18n>No Data</div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
