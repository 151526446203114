<table class="table col-12">
  <thead>
    <tr>
      <th>#</th>
      <th scope="col" i18n>Skill Name</th>
      <th scope="col" i18n>Link</th>
    </tr>
  </thead>
  <tbody *ngFor="let mem of this.memoryList; let i= index">
    <tr>
      <td rowspan="2" class="align-middle forLevel">Lv.{{ i + 1 }}</td>
      <td>{{ mem.memoryTitle }}</td>
      <td rowspan="2" class="align-middle">
        <span *ngIf="mem.extraEffect" class="wrapup">{{ this.addLineBreak(mem.extraEffect.extraEffectDesc) }}</span>
      </td>
    </tr>
    <tr>
      <td class="align-middle">
        <span class="wrapup">{{ this.addLineBreak(mem.memoryDesc) }}</span>
      </td>
    </tr>
  </tbody>
</table>
