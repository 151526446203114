<div class="card ps-0 pe-0 mt-3">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a
          href="javascript: void()"
          class="nav-link"
          (click)="changeDisplay(0);"
          [ngClass]="{ disabled: this.overview, active: this.overview }" i18n>Overview</a>
      </li>
      <li class="nav-item">
        <a
          href="javascript: void()"
          class="nav-link"
          (click)="changeDisplay(1);"
          [ngClass]="{ disabled: this.limited, active: this.limited }" i18n>Limited</a>
      </li>
      <li class="nav-item">
        <a
          href="javascript: void()"
          class="nav-link"
          (click)="changeDisplay(2);"
          [ngClass]="{ disabled: this.general, active: this.general }" i18n>Permanant</a>
      </li>
    </ul>
  </div>
  <div class="card-body d-flex h-auto overflow-auto container justify-content-center">
    <app-timesubtable
      *ngIf="this.allTimetable"
      [timetable]="this.allTimetable"
      class="subtable"
      [ngClass]="{ 'd-none': !this.overview }">
    </app-timesubtable>
    <app-timesubtable
      *ngIf="this.limitedTimetable"
      [timetable]="this.limitedTimetable"
      class="subtable"
      [ngClass]="{ 'd-none': !this.limited }">
    </app-timesubtable>
    <app-timesubtable
      *ngIf="this.generalTimetable"
      [timetable]="this.generalTimetable"
      class="subtable"
      [ngClass]="{ 'd-none': !this.general }">
    </app-timesubtable>
  </div>
</div>
