<div class="card ps-0 pe-0">
  <div class="card-header">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a href="javascript: void()" class="nav-link disabled d-none d-md-block">
          Level:
        </a>
        <a href="javascript: void()" class="nav-link disabled d-md-none">
          Lv:
        </a>
      </li>
      <li class="nav-item" *ngFor="let thisBound of this.getBoundFromRarity(); let indexOfelement= index;">
        <a href="javascript: void()"
          class="nav-link"
          [ngClass]="{'active': this.activeArray[indexOfelement], 'disabled': this.activeArray[indexOfelement]}"
          (click)="this.setActiveTab(indexOfelement);">
          {{thisBound}}
        </a>
      </li>
    </ul>
  </div>
  <div class="card-body">
    <div *ngFor="let i of this.getBoundFromRarity(); let thisBound= index" [ngClass]="{'d-none': !this.activeArray[thisBound]}">
      <table class="table table-hover">
        <thead>
          <tr>
            <th i18n>Skill Name</th>
            <th i18n>Skill Desc</th>
            <th i18n>Lv.</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let j of this.getSkillWithinBound(thisBound)">
            <td>{{j.skillName}}</td>
            <td>{{j.skillDesc}}</td>
            <td>{{j.skillLevel}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
