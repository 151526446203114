<div class="row m-0 p-0 w-100">
  <div class="col-4 ps-0 pe-0">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2" i18n>Aptitudes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td i18n>Cute</td>
          <td>{{ this.getAttribute("cute") }}</td>
        </tr>
        <tr>
          <td i18n>Cool</td>
          <td>{{ this.getAttribute("cool") }}</td>
        </tr>
        <tr>
          <td i18n>Positive</td>
          <td>{{ this.getAttribute("positive") }}</td>
        </tr>
        <tr>
          <td i18n>Relax</td>
          <td>{{ this.getAttribute("relax") }}</td>
        </tr>
        <tr>
          <td i18n>Emotional</td>
          <td>{{ this.getAttribute("emotional") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4 ps-0 pe-0">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2" i18n>Express Aptitudes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td i18n>High Pace</td>
          <td>{{ this.getAttribute("high") }}</td>
        </tr>
        <tr>
          <td i18n>Middle Pace</td>
          <td>{{ this.getAttribute("medium") }}</td>
        </tr>
        <tr>
          <td i18n>Slow Pace</td>
          <td>{{ this.getAttribute("slow") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-4 ps-0 pe-0">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2" i18n>Environment Aptitudes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td i18n>Fine</td>
          <td>{{ this.getAttribute("fine") }}</td>
        </tr>
        <tr>
          <td i18n>Sunny</td>
          <td>{{ this.getAttribute("sunny") }}</td>
        </tr>
        <tr>
          <td i18n>Cloudy</td>
          <td>{{ this.getAttribute("cloudy") }}</td>
        </tr>
        <tr>
          <td i18n>Rain</td>
          <td>{{ this.getAttribute("rain") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>