<div class="form-group d-flex" #skillForm>
  <select class="form-select d-inline-block m-1" style="width: 40%;" (change)="this.subOption(); this.updateQueryStr()" #skillNameSelect>
    <option value="" subOption="0" selected>--</option>
    <option *ngFor="let i of this.skillList" value={{i.supSkillName}} attr.subOption={{i.hasSubOption}}>{{i.supSkillName}}</option>
  </select>
  <select class="form-select d-inline-block m-1" style="width: 40%;" (change)="this.updateQueryStr()" disabled #skillSubOption>
    <option value>--</option>
    <option value="Vo">Vocal</option>
    <option value="Da">Dance</option>
    <option value="Vi">Visual</option>
    <option value="Me">Mental</option>
    <option value="Sp">SP</option>
    <option value="体力">Stamina Recovery</option>
    <option value="Vo上限">Vocal Limit</option>
    <option value="Da上限">Dance Limit</option>
    <option value="Vi上限">Visual Limit</option>
  </select>
  <input type="number" name="" style="width: 20%;" class="form-control d-inline-block m-1" (change)="this.updateQueryStr()" placeholder="level" #skillLevelSelect>
</div>