<div class="col-lg-4 col-md-12 text-center">
  <ng-container i18n>Release</ng-container>:
  {{ this.releaseDate }}
</div>
<div class="col-lg-4 col-md-12 text-center">
  <ng-container i18n>Gasha</ng-container>:
  {{ this.getMethod }}
</div>
<div class="col-lg-4 col-md-12 text-center" >
  <ng-container i18n>Rarity</ng-container>:
  {{ this.rarity }}
</div>